import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import config from '../../config'
import FourOhFour from '../../../common/components/Error'

const RootIndex = ({ data }) => {
  return (
    <Layout location={'home'} config={config}>
      <FourOhFour configEmail={config.authorDefaultEmail} />
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
