import styled from 'styled-components'
import { space, color, typography, layout } from 'styled-system'

export const FourOhFourContainer = styled.div`
    ${color}
    ${layout}
    ${typography}
    ${space}

    & a {
      color: #afa9a0;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
`
export const ErrorContainer = styled.div`
  ${layout}
  ${space}
`
export const H1 = styled.h1`
  ${color}
  ${layout}
  ${typography}
  ${space}
`
export const H2 = styled.h2`
  ${color}
  ${layout}
  ${typography}
  ${space}
`
