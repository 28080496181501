import React from 'react'
import { A } from '../Button/index'
import { FourOhFourContainer, ErrorContainer, H1, H2 } from './style'
import PropTypes from 'prop-types'

const FourOhFour = ({ configEmail }) => {
  return (
    <FourOhFourContainer
      bg={`dark.bg`}
      m="0 auto"
      color="dark.text"
      minHeight="100vh"
      fontFamily="headings"
    >
      <ErrorContainer maxWidth="maxWidth.medium" m="0 auto" p="20vh 60px 0px">
        <H1 fontSize={100}>404</H1>
        <br />
        <H2 fontSize="2em">The page you were looking for doesn&#39;t exist.</H2>
        <br />
        <ErrorContainer color="dark.text" fontSize={18}>
          Visit our <A to="/">Homepage</A> or we&#39;d be happy to{' '}
          <a href={`mailto:${configEmail}`}>Help You</a>.
        </ErrorContainer>
      </ErrorContainer>
    </FourOhFourContainer>
  )
}

FourOhFour.propTypes = {
  configEmail: PropTypes.string,
}

export default FourOhFour
